import { useRoutes } from 'react-router-dom';
import router from 'src/router';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { Box, Button, CssBaseline, Typography, styled } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { ScreenshotMonitor } from '@mui/icons-material';
import { useEffect, useState } from 'react';

const BtnFull = styled(Button)(
  ({ theme }) => `  
    padding: 0;
    z-Index: 1000; 
    opacity: 0.7;
    display: block;
`
);
function App() {
  const content = useRoutes(router);
  const [isfullScreen, setfullScreen] = useState<boolean>(false);
  const handle = useFullScreenHandle();

  const full = () => {
    if (isfullScreen) {
      handle.exit();
      setfullScreen(false);
    } else {
      handle.enter();
      setfullScreen(true);
    }
  };
  /* useEffect(() => {
    full();
  }, []); */
  return (
    <>
      {/* <Box sx={{background:'transparent'}}> */}
      <FullScreen handle={handle}>
        <ThemeProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <CssBaseline />
            <Box
              alignItems={'center'}
              justifyContent={'flex-end'}
              width={'100%'}
              display={'flex'}
              position={'fixed'}
              zIndex={1000}
              bottom={'50px'}
              right={'50px'}
            >
              <BtnFull onClick={full}>
                <ScreenshotMonitor fontSize="large" />
                <Typography sx={{ top: '-10', position: 'relative' }}>
                  {isfullScreen ? 'Salir FullScreen' : 'FullScreen'}
                </Typography>
              </BtnFull>
            </Box>
            {content}
          </LocalizationProvider>
        </ThemeProvider>
      </FullScreen>
      {/* </Box >  */}
    </>
  );
}
export default App;

import {
  Apps,
  CloudDoneOutlined,
  CookieOutlined,
  DeliveryDiningOutlined,
  Leaderboard,
  MoneyOffOutlined,
  MonitorOutlined,
  QrCode2Outlined,
  QueryStats,
  Receipt,
  Schema,
  ScreenShare,
  TableBarOutlined
} from '@mui/icons-material';
import { useRef, useState } from 'react';
import {
  Box as Box,
  Button as IconButton,
  List,
  ListItem,
  ListSubheader,
  Popover,
  Typography,
  styled,
  useTheme,
  alpha,
  Divider,
  Grid,
  Button
} from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import BrightnessLowTwoToneIcon from '@mui/icons-material/BrightnessLowTwoTone';

const BtnApp = styled(Button)(
  ({ theme }) => `
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};
          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};
            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          } 
          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};
            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root { 
      .MuiListItem-root {
        padding: 1px 0;
        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};
          .MuiBadge-standard {
            background: ${theme.colors.secondary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};
          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};
            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }
          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }
          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};
            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }
        &.Mui-children {
          flex-direction: column;
          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }
        .MuiCollapse-root {
          width: 100%;
          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }
          .MuiListItem-root {
            padding: 1px 0;
            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};
              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }
              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
                  'transform',
                  'opacity'
                ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }
              &.active,
              &:hover {
                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
     
`
);
const Iconbtn = styled(IconButton)(
  ({ theme }) => `
  padding: ${theme.spacing(1)};
  margin: ${theme.spacing(0)};
`
);
const GridStyled = styled(Grid)(
  ({ theme }) => `
        padding : ${theme.spacing(0.7)}; 
            .MuiButton-root {
              display: grid;
              color: ${theme.colors.alpha.trueWhite[70]};
              background-color: transparent;
              width: 100%;
              justify-content: center;
              padding: ${theme.spacing(0.5)};
              .MuiButton-startIcon,
              .MuiButton-endIcon {
                transition: ${theme.transitions.create(['color'])};
                .MuiSvgIcon-root {
                  font-size: inherit;
                  transition: none;
                }
              }
              .MuiButton-startIcon {
                color: ${theme.colors.alpha.trueWhite[30]};
                font-size: ${theme.typography.pxToRem(20)};
                margin-right: ${theme.spacing(1)};
              }
              .MuiButton-endIcon {
                color: ${theme.colors.alpha.trueWhite[50]};
                margin-left: auto;
                opacity: .8;
                font-size: ${theme.typography.pxToRem(20)};
              }
              &.active,
              &:hover {
                background-color: ${alpha(
                  theme.colors.alpha.trueWhite[100],
                  0.06
                )};
                color: ${theme.colors.warning.main};
                .MuiButton-startIcon,
                .MuiButton-endIcon {
                  color: ${theme.colors.alpha.trueWhite[100]};
                }
              }
            }
            &.Mui-children {
              flex-direction: column;
              .MuiBadge-root {
                position: absolute;
                right: ${theme.spacing(7)};
              }
            }
          
`
);

const DividerStyled = styled(Divider)(
  ({ theme }) => `
  margin-top: ${theme.spacing(1)};
  margin-bottom: ${theme.spacing(1)};
  background: ${theme.colors.alpha.trueWhite[10]};
  .MuiDivider-wrapper{
    background:${theme.colors.alpha.trueWhite[30]};
    color:${theme.colors.alpha.trueWhite[100]};
    border-radius:${theme.general.borderRadius};
   }

`
);
export default function MenuAPP() {
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const theme = useTheme();

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <Iconbtn
        color="primary"
        ref={ref}
        onClick={handleOpen}
        sx={{
          color: theme.colors.alpha.white[100],
          '--IconButton-size': theme.spacing(20)
        }}
      >
        <Apps fontSize="large" />
      </Iconbtn>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Box
          sx={{
            background: theme.colors.alpha.black[100],
            maxHeight: '25%',
            maxWidth: '100%',
            minWidth: '10%'
          }}
        >
          <List component="div">
            <DividerStyled>
              <Typography variant="h6">Admin</Typography>
            </DividerStyled>
            <SubMenuWrapper>
              <GridStyled
                container
                direction="row"
                alignItems="center"
                justifyContent="sflex-start"
                sx={{
                  border: `${theme.colors.alpha.black[10]} solid 1px`,
                  borderRadius: theme.general.borderRadius,
                  marginBottom: theme.spacing(0.5),
                  marginTop: theme.spacing(0.5)
                }}
              >
                <GridStyled item xs={6} md={4}>
                  <IconButton
                    color="primary"
                    component={RouterLink}
                    onClick={handleClose}
                    to="/dashboards/index"
                    sx={{
                      textDecoration: 'none',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <BrightnessLowTwoToneIcon fontSize="large" />
                    <Typography noWrap>Escritorio</Typography>
                  </IconButton>
                </GridStyled>
                <GridStyled item xs={6} md={4}>
                  <IconButton
                    color="primary"
                    component={RouterLink}
                    onClick={handleClose}
                    to="/admin/creadormenus"
                    sx={{ textDecoration: 'none' }}
                  >
                    <QrCode2Outlined fontSize="large" />
                    <Typography noWrap>Items</Typography>
                  </IconButton>
                </GridStyled>
                <GridStyled item xs={6} md={4}>
                  <IconButton
                    color="primary"
                    component={RouterLink}
                    onClick={handleClose}
                    to="/admin/editorrecetas"
                    sx={{
                      textDecoration: 'none',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <CookieOutlined fontSize="large" />
                    <Typography noWrap>Recetas</Typography>
                  </IconButton>
                </GridStyled>
              </GridStyled>
            </SubMenuWrapper>
          </List>
          <List component="div">
            <DividerStyled>
              <Typography variant="h6">Apps</Typography>
            </DividerStyled>
            <SubMenuWrapper>
              <GridStyled
                container
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                sx={{
                  border: `${theme.colors.alpha.black[10]} solid 1px`,
                  borderRadius: theme.general.borderRadius,
                  marginBottom: theme.spacing(0.5),
                  marginTop: theme.spacing(0.5)
                }}
              >
                <GridStyled item xs={6} md={4}>
                  <IconButton
                    color="primary"
                    component={RouterLink}
                    onClick={handleClose}
                    to="/apps/delivery"
                    sx={{
                      textDecoration: 'none',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <DeliveryDiningOutlined fontSize="large" />
                    <Typography noWrap>Delivery</Typography>
                  </IconButton>
                </GridStyled>
                <GridStyled item xs={6} md={4}>
                  <IconButton
                    color="primary"
                    component={RouterLink}
                    onClick={handleClose}
                    to="/apps/mesas"
                    sx={{ textDecoration: 'none' }}
                  >
                    <TableBarOutlined fontSize="large" />
                    <Typography noWrap>Mesas</Typography>
                  </IconButton>
                </GridStyled>
                <GridStyled item xs={6} md={4}>
                  <IconButton
                    color="primary"
                    component={RouterLink}
                    onClick={handleClose}
                    to="/apps/pedidos"
                    sx={{
                      textDecoration: 'none',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Receipt fontSize="large" />
                    <Typography noWrap>Pedidos</Typography>
                  </IconButton>
                </GridStyled>
                <GridStyled item xs={6} md={4}>
                  <IconButton
                    color="primary"
                    component={RouterLink}
                    onClick={handleClose}
                    to="/apps/montador"
                    sx={{
                      textDecoration: 'none',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <ScreenShare fontSize="large" />
                    <Typography noWrap>Montador</Typography>
                  </IconButton>
                </GridStyled>
                <GridStyled item xs={6} md={4}>
                  <IconButton
                    color="primary"
                    component={RouterLink}
                    onClick={handleClose}
                    to="/apps/cocina"
                    sx={{
                      textDecoration: 'none',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <MonitorOutlined fontSize="large" />
                    <Typography noWrap>KDS</Typography>
                  </IconButton>
                </GridStyled>
              </GridStyled>
              {/*   <Divider sx={{ background: theme.colors.alpha.trueWhite[10] }} />
              <List component="div">
                <ListItem component="div">
                  <IconButton
                    disableRipple
                    component={RouterLink}
                    onClick={handleClose}
                    to="/pos/delivery"
                    startIcon={<DeliveryDiningOutlined />}
                    sx={{ padding: `${theme.spacing(1)} !important` }}
                  >
                    Delivery
                  </IconButton>
                </ListItem>
                <ListItem component="div">
                  <IconButton
                    disableRipple
                    component={RouterLink}
                    onClick={handleClose}
                    to="/pos/mesas"
                    startIcon={<TableBarOutlined />}
                    sx={{ padding: `${theme.spacing(1)} !important` }}
                  >
                    Mesas
                  </IconButton>
                </ListItem>
                <ListItem component="div">
                  <IconButton
                    disableRipple
                    component={RouterLink}
                    onClick={handleClose}
                    to="/pos/pedidos"
                    startIcon={<Receipt />}
                    sx={{ padding: `${theme.spacing(1)} !important` }}
                  >
                    Pedidos
                  </IconButton>
                </ListItem>
                <ListItem component="div">
                  <IconButton
                    disableRipple
                    component={RouterLink}
                    onClick={handleClose}
                    to="/pos/montador"
                    startIcon={<ScreenShare />}
                    sx={{ padding: `${theme.spacing(1)} !important` }}
                  >
                    Montador
                  </IconButton>
                </ListItem>
                <ListItem component="div">
                  <IconButton
                    disableRipple
                    component={RouterLink}
                    onClick={handleClose}
                    to="/pos/cocina"
                    startIcon={<MonitorOutlined />}
                    sx={{ padding: `${theme.spacing(1)} !important` }}
                  >
                    Cocina KDS
                  </IconButton>
                </ListItem>
              </List> */}
            </SubMenuWrapper>
          </List>
          <List component="div">
            <SubMenuWrapper>
              <DividerStyled>
                <Typography variant="h6">Reportes</Typography>
              </DividerStyled>
              <SubMenuWrapper>
                <GridStyled
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  sx={{
                    border: `${theme.colors.alpha.black[10]} solid 1px`,
                    borderRadius: theme.general.borderRadius,
                    marginBottom: theme.spacing(0.5),
                    marginTop: theme.spacing(0.5)
                  }}
                >
                  <GridStyled item xs={6} md={4}>
                    <IconButton
                      color="primary"
                      component={RouterLink}
                      onClick={handleClose}
                      to="/admin/archivos"
                      sx={{
                        textDecoration: 'none',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <Schema fontSize="large" />
                      <Typography noWrap>Archivos</Typography>
                    </IconButton>
                  </GridStyled>
                  <GridStyled item xs={6} md={4}>
                    <IconButton
                      color="primary"
                      component={RouterLink}
                      onClick={handleClose}
                      to="/transactions/ventasdiaria"
                      sx={{ textDecoration: 'none' }}
                    >
                      <QueryStats fontSize="large" />
                      <Typography noWrap>Ventas Diarias</Typography>
                    </IconButton>
                  </GridStyled>
                  <GridStyled item xs={6} md={4}>
                    <IconButton
                      color="primary"
                      component={RouterLink}
                      onClick={handleClose}
                      to="/transactions/estadisticas"
                      sx={{ textDecoration: 'none' }}
                    >
                      <Leaderboard fontSize="large" />
                      <Typography noWrap>Estadísticas</Typography>
                    </IconButton>
                  </GridStyled>
                </GridStyled>
              </SubMenuWrapper>
            </SubMenuWrapper>
          </List>
        </Box>
      </Popover>
    </>
  );
}
